import React from "react"
import {Route, Routes, useParams} from "react-router-dom"
import {setRegionFromRegionNumber} from "@indebted/api"
import {NotFoundPage} from "@indebted/components/Error"

import {FrequencyPage} from "./FrequencyPage"
import {StartDatePage} from "./StartDatePage"

function PaymentArrangementRouter() {
	const {region} = useParams()
	setRegionFromRegionNumber(region)
	return (
		<Routes>
			<Route element={<FrequencyPage />} path="/:id/frequency" />
			<Route element={<StartDatePage />} path="/:id/start-date" />
			<Route element={<NotFoundPage />} path="*" />
		</Routes>
	)
}

export {PaymentArrangementRouter}
