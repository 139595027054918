import React, {useEffect, useState} from "react"
import {paymentArrangementAPI, getRegionNumber} from "@indebted/api"
import {useNavigate, useParams} from "react-router-dom"
import {Page} from "@indebted/components/Page"
import Grid from "@mui/material/Unstable_Grid2"
import {Button, Typography} from "@mui/material"
import styled from "styled-components"
import {LoadingPage} from "@indebted/components/Loading"
import {ErrorPage} from "@indebted/components/Error"
import {useNotification} from "@indebted/components/Notification"

import {FrequencyPageI18nType, FrequencyPageOptionType, FrequencyPageType} from "./types"

const FrequencyForm = ({
	i18n,
	frequencies,
	paymentArrangementID,
}: {
	i18n: FrequencyPageI18nType
	frequencies: FrequencyPageOptionType[]
	paymentArrangementID: string
}) => {
	const navigate = useNavigate()
	const {notification} = useNotification()

	const onSubmit = (frequency: string) => {
		paymentArrangementAPI
			.configureFrequency({paymentArrangementID, frequency})
			.then(() => {
				navigate(`/${getRegionNumber()}/payment-arrangement/${paymentArrangementID}/start-date`)
			})
			.catch((error) => {
				notification.error(error.Message, 5000)
			})
	}

	return (
		<Page padded>
			<Grid container direction="column" alignItems="stretch" justifyContent="space-between">
				<Grid padding={0}>
					<Grid container direction="column" alignItems="stretch" spacing={5}>
						<Grid>
							<Typography variant="h6">{i18n.Header}</Typography>
						</Grid>
						<Grid>
							<Typography>{i18n.Title}</Typography>
						</Grid>
					</Grid>

					<ButtonsGrid container direction="column" alignItems="stretch" spacing={5}>
						<Grid>
							<Typography>{i18n.Question}</Typography>
						</Grid>

						{frequencies.map((frequency) => (
							<Grid key={frequency.ID} data-testid={frequency.ID}>
								<Button fullWidth variant="outlined" onClick={() => onSubmit(frequency.ID)}>
									{frequency.DisplayText}
								</Button>
							</Grid>
						))}
					</ButtonsGrid>
				</Grid>
			</Grid>
		</Page>
	)
}

const ButtonsGrid = styled(Grid)`
	margin-top: 20px;
`

const FrequencyPage = () => {
	const {id: paymentArrangementID} = useParams()
	const [Component, setComponent] = useState(<LoadingPage />)
	useEffect(() => {
		paymentArrangementAPI
			.getConfigureFrequencyPage({paymentArrangementID})
			.then((result: FrequencyPageType) => {
				setComponent(
					<FrequencyForm
						i18n={result.I18n}
						frequencies={result.FrequencyOptions}
						paymentArrangementID={paymentArrangementID}
					/>,
				)
			})
			.catch((error) => {
				setComponent(<ErrorPage message={error.Message} error={error} />)
			})
	}, [paymentArrangementID])

	return Component
}

export {FrequencyPage, FrequencyForm}
